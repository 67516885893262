import React from 'react';


const Roadmap = () => {

  return (
    <>
      <img src="sodabits-roadmap.png"  style={{width: '100%'}} />
    </>
  );
};

export default Roadmap;
