function shuffle(sourceIds) {
  // Fisher-Yates
  for (let idx = sourceIds.length - 1; idx > 0; idx--) {
      const j = Math.floor(Math.random() * (idx + 1));
      [sourceIds[idx], sourceIds[j]] = [sourceIds[j], sourceIds[idx]];
  }

  return sourceIds;
}

export default shuffle;
