import { LazyLoadImage, trackWindowScroll } from 'react-lazy-load-image-component';
import SodabitLinkMap from './data/sodabits_link_map.json';
import ReservedIds from './data/reserved.json';

import shuffle from './shuffle';


const ResultItem = trackWindowScroll(({groupName='', bits=[], attributeName='Type', availSuffix='', showAllLink=true, showCount=true, showOnlyAvailable=false, showAll=false, ignoreReserved=false, showBuy=false, scrollPosition, groupLink }) => {
  let count = bits.length;
  let bitsToShow = [...bits];
  let availableBits = bits.filter((id) => !window.SoldSodabitsIDS.has(id));
  if (!ignoreReserved) {
    availableBits = availableBits.filter((id) => !ReservedIds.includes(id));
  }
  let availableCount = availableBits.length;
  let rowsData = [];

  if (showOnlyAvailable) {
    bitsToShow = availableBits;
  }

  // randomize only when showing a small sample of bits
  // and show up to 6 per row
  if (!showAll) {
    bitsToShow = shuffle(bitsToShow);
    rowsData.push(bitsToShow.splice(0,6));
  } else {
    while(bitsToShow.length) {
      rowsData.push(bitsToShow.splice(0,6));
    }
  }

  groupLink = groupLink || `https://opensea.io/assets/sodabits?search[resultModel]=ASSETS&search[stringTraits][0][name]=${attributeName}&search[stringTraits][0][values][0]=${groupName}`;

  return rowsData.map((row, idx) => {
    const isFirstRow = (idx === 0);

    return (<tr key={idx}>
      {isFirstRow ? (<>
        {showAllLink &&
          <th> <span className="name"> <a target="_blank" rel="noreferrer" href={groupLink} title={`See all ${groupName} SodaBits`}> { groupName } </a></span></th>
        }
        { !showAllLink &&
          <th> <span className="name"> { groupName } </span></th>
        }
        {showCount &&
        <td> { count } </td>
        }
        <td> { availableCount } <span className="avail"> { availSuffix } </span> </td>
      </>) : (<>
        {showCount &&
        <td> </td>
        }
        <td> </td>
        <td> </td>
      </>)}
      {
        row.map((id) => {
          let { name, url } = SodabitLinkMap[id];
          let image = `/sodabits/${id}.png`;

          return (
            <td key={id} className="table-img">
              <a target="_blank" rel="noreferrer" href={url} title={name}>
                <LazyLoadImage className=""
                  alt={name}
                  placeholderSrc="/placeholder.png"
                  scrollPosition={scrollPosition}
                  src={image}
                />
              {showBuy && "BUY"}
              </a>
            </td>
          );
        })
      }

      { isFirstRow && showAllLink && (count > 6) &&
        <td> <span className="name"> <a target="_blank" rel="noreferrer" href={groupLink} title={`See all ${groupName} SodaBits`}> All + </a></span></td>
      }
    </tr>
    );
  });
});

const ResultTable = ({ items={}, attributeName, availSuffix, showBuy=false, showAllLink=true,  showCount=true, showAll, showOnlyAvailable, ignoreReserved, sort=true, groupLinkMap={}}) => {
  let rowData = Object.entries(items);

  if (sort) {
    rowData = rowData.sort(([, bitsA], [, bitsB]) => bitsA.length - bitsB.length)
  }

  return (
    <div className="table-responsive">
      <table className="table">
        <thead>
          <tr>
            <th scope="col">Name</th>
            {showCount &&
            <th scope="col">#</th>
            }
            <th scope="col">Avail</th>
            <th scope="col">Examples</th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          {
            rowData.map(([groupName, bits]) => {
              return <ResultItem key={groupName} groupLink={groupLinkMap[groupName]} {...{showBuy, showCount, availSuffix, groupName, bits, attributeName, showAllLink, showAll, showOnlyAvailable, ignoreReserved}} />
            })
          }
        </tbody>

      </table>
    </div>
  );
};

export default ResultTable;
