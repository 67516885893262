import React, { useState, useEffect } from 'react';

import SodabitLinkMap from './data/sodabits_link_map.json';
import FreeSodabitIds from './data/free_sodabits.json';
import ReservedIds from './data/reserved.json';
import TokensData from './data/token_ids.json';

import shuffle from './shuffle';


let tokenIdToIdMap = {};

Object.values(TokensData).forEach((data) => {
  tokenIdToIdMap[data.token_id] = data.id;
});


const BlockRow = ({ids}) => {
  return (
    <tr className="sodabits-block-row">
    {
      ids.map((id) => {
        let { name, url } = SodabitLinkMap[id];
        let image = `/sodabits/${id}.png`;
        return (
          <td key={id}>
            <a target="_blank" rel="noreferrer" href={url} title={name}>
              <img className="table-img pixelated" alt={name} src={image} />
            </a>
          </td>
        );
      })
    }
    </tr>
  );
};


const SoldBlock = () => {

  const apiURL = 'https://api.opensea.io/api/v1/assets?order_by=sale_price&order_direction=desc&offset=0&limit=20&collection=sodabits';
  const viewAllURL = 'https://opensea.io/assets/sodabits?search[sortAscending]=false&search[sortBy]=LAST_SALE_PRICE';

  const [ids, setIds] = useState();

  useEffect(() => {
    async function getIds() {
      const response = await fetch(apiURL);
      if (response.ok) {
        const data = await response.json();
        let ids = data.assets.map((a) => tokenIdToIdMap[a.token_id]);
        setIds(ids);
      }
    }

    getIds();
  }, []);


  if (!ids) {
    return null;
  }

  return (
    <>
      <div className="h1 mt-3 text-start block-header">
        <a target="_blank" rel="noreferrer" href={viewAllURL}> Recently Sold </a>
        <a className="view-all" target="_blank" rel="noreferrer" href={viewAllURL}> view all </a>
      </div>

      <div className="sold-block sodabits-block mb-5">

        <div className="table-responsive">
          <table className="table">
            <tbody>
              <BlockRow ids={ids.slice(0,8)} />
              <BlockRow ids={ids.slice(8,16)} />
            </tbody>
          </table>
        </div>

      </div>
    </>
  );
};

const FreeBlock = () => {

  const viewAllURL = 'https://opensea.io/assets/sodabits?search[toggles][0]=BUY_NOW';

  let freeIds = Object.keys(SodabitLinkMap);

  freeIds = shuffle(freeIds);

  return (
    <>
      <div className="h1 mt-3 text-start block-header">
        For <a target="_blank" rel="noreferrer" href={viewAllURL}> SALE </a>
        <a className="view-all" target="_blank" rel="noreferrer" href={viewAllURL}> view all </a>
      </div>

      <div className="free-block sodabits-block mb-5">

        <div className="table-responsive">
          <table className="table">
            <tbody>
              <BlockRow ids={freeIds.slice(0,8)} />
              <BlockRow ids={freeIds.slice(8,16)} />
            </tbody>
          </table>
        </div>

      </div>
    </>
  );
};

export { FreeBlock, SoldBlock }
