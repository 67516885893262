const Header = () => {
  return (
    <header>
        <div className="d-flex flex-column flex-md-row align-items-center pb-3 mb-4">
            <a href="/" className="d-flex align-items-center text-dark text-decoration-none">
                <span className="fs-4"><img className="logo" alt="sodabits logo" src="/sodabits_banner.png" /></span>
            </a>
        </div>
    </header>
  );
};

export default Header;
