import React, { useState } from 'react';


const StickyFooter = () => {
  const [ show, setShow ] = useState(true);

  if (!show) {
    return null;
  }

  return (
    <div className="fixed-bottom container container-fluid py-3 mb-3">
      <div className="row justify-content-end">
        <div className="col col-sm py-1 sticky-footer">
          <a target="_blank" rel="noreferrer" href="https://discord.com/invite/kf7MteQjNe">  <img src="ico_discord.gif" alt="Follow us on Discord"   height="90%" /> </a>
          <a target="_blank" rel="noreferrer" href="https://twitter.com/sodabits_io">        <img src="ico_twitter.gif" alt="Follow us on Twitter"   height="90%" /> </a>
          <a target="_blank" rel="noreferrer" href="https://www.instagram.com/sodabits.io/"> <img src="ico_insta.gif"   alt="Follow us on Instagram" height="90%" /> </a>

          <button type="button" className="btn-close" aria-label="Close" onClick={() => setShow(false)}> </button>
        </div>
      </div>
    </div>
  );
};

export default StickyFooter;
