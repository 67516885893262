import Header from './Header';
import Footer from './Footer';
import StickyFooter from './StickyFooter';
import ResultTable from './ResultTable';
import Roadmap from './Roadmap';
import { SoldBlock, FreeBlock } from './Blocks';

import SodabitTypes from './data/sodabits_types_map.json';
import SodabitAttributes from './data/sodabits_attributes_map.json';
import SodabitsByPropertyCount from './data/sodabits_property_count_map.json';


function App() {
  const rarityLinks = {
    '1 Property': 'https://rarity.tools/sodabits?filters=$Trait%20Count$0:true',
    '2 Properties': 'https://rarity.tools/sodabits?filters=$Trait%20Count$1:true',
    '3 Properties': 'https://rarity.tools/sodabits?filters=$Trait%20Count$2:true',
    '4 Properties': 'https://rarity.tools/sodabits?filters=$Trait%20Count$3:true',
    '5 Properties': 'https://rarity.tools/sodabits?filters=$Trait%20Count$4:true',
    '6 Properties': 'https://rarity.tools/sodabits?filters=$Trait%20Count$5:true',
    '7 Properties': 'https://rarity.tools/sodabits?filters=$Trait%20Count$6:true',
    '8 Properties': 'https://rarity.tools/sodabits?filters=$Trait%20Count$7:true',
    '9 Properties': 'https://rarity.tools/sodabits?filters=$Trait%20Count$8:true',
    '10 Properties': 'https://rarity.tools/sodabits?filters=$Trait%20Count$9:true',
  }

  return (<>
    <div className="container py-3">
      <Header />
      <main>

        <h1 id="about"> sodabits </h1>
        <blockquote className="text-left text-break lh-lg mb-2">
          <p>
    Sodabits are the first "Non Fungible Tokens (NFTs)" that pay tribute to women who are pioneers in social media, gaming, streaming and content creation. Sodabits are 33x73 computer generated pixel art, combining 73 different visual properties of clothes, accessories, and styles. You can buy/sell/trade Sodabits of all kinds from the girl next door to a fantasy role player. There are only 1000 unique collectible Sodabit characters with proof of ownership stored on the Ethereum blockchain.

    Be the first to own your favorite online muse today!
          </p>
        </blockquote>

        <div className="row">
          <div className="col text-center">
            <a href="/island" className="btn btn-slot-machine">
              <img src="/island_emoji.png" className="island-emoji"/>
              Sodabit Island
            </a>
          </div>
        </div>

        <div className="row">
          <div className="col text-center">
            <a href="/raffle" className="btn btn-slot-machine">
              <img src="/raffle_emoji.png" className="slotmachine-emoji"/>
              Buy Sodabits
            </a>
          </div>

          <div className="col text-center">
            <a href="/pfp" className="btn btn-slot-machine">
                Profile Pic Generator
            </a>
          </div>
        </div>

        <blockquote className="twitter-tweet">
          <p lang="en" dir="ltr">
            ATTN: Sodabit Island is live &nbsp;
            <a href="https://t.co/532qPDpb03">
              https://t.co/532qPDpb03
            </a>
            :desert_island:
            <a href="https://twitter.com/hashtag/NFTCommmunity">
              #NFTCommmunity &nbsp;
            </a>
            <a href="https://t.co/MELIdsCUIw">pic.twitter.com/MELIdsCUIw
            </a>
            </p>
              &mdash; SodaBits NFT (@SodaBits_io)
            <a href="https://twitter.com/SodaBits_io/status/1483916829173166086">
              January 19, 2022 &nbsp;
            </a>
        </blockquote>

        <Roadmap />

        <SoldBlock />

        <FreeBlock />

        <h1 className="text-start">Types &amp; Properties</h1>

        <h3 className="my-4 strong text-start">Types</h3>
        <ResultTable items={SodabitTypes}/>

        <h3 className="my-4 strong text-start">Properties</h3>
        <ResultTable items={SodabitAttributes} attributeName="Property"/>

        <h3 className="my-4 strong text-start">Property Totals</h3>
        <ResultTable items={SodabitsByPropertyCount} groupLinkMap={rarityLinks} sort={false}/>
      </main>
    </div>

    <StickyFooter />
    <Footer />
  </>);
}

export default App;
